import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { PageSection } from '@move-ui/layout';

import PageTracking, {
  getGoogleTagManagerPageType,
} from '../../utils/tracking/googleTagManager';
import Error from '../../shared/components/Error';
import Head from '../../../shared/components/Head';

const propTypes = {
  page: PropTypes.string.isRequired,
};

const NotFoundPage = ({ page }) => {
  const { formatMessage } = useIntl();

  return (
    <PageTracking page={page}>
      <Head title={formatMessage({ id: '404_error_title' })} description="" />
      <div data-testid="notFoundPage">
        <PageSection>
          <Error
            trackingCategory={getGoogleTagManagerPageType(page)}
            headline={formatMessage({ id: '404_error_title' })}
            subline={formatMessage({ id: '404_error_subtitle' })}
          />
        </PageSection>
      </div>
    </PageTracking>
  );
};

NotFoundPage.propTypes = propTypes;

export default NotFoundPage;
